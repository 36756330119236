import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BaseLayoutComponent } from "src/app/modules/main/base-layout/base-layout.component";
import { FooterComponent } from "src/app/modules/main/footer/footer.component";
import { HeaderComponent } from "src/app/modules/main/header/header.component";
import { PageTitleComponent } from "src/app/modules/main/page-title/page-title.component";
import { PagesLayoutComponent } from "src/app/modules/main/pages-layout/pages-layout.component";
import { SidebarComponent } from "src/app/modules/main/sidebar/sidebar.component";
import { Route, RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { SearchBoxComponent } from "src/app/modules/main/header/search-box/search-box.component";
import { UserBoxComponent } from "src/app/modules/main/header/user-box/user-box.component";
import { PATH } from "src/app/common/constant";
import { DashboardComponent } from "src/app/modules/main/dashboard/dashboard.component";
import { NgScrollbarModule } from 'ngx-scrollbar';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "@danielmoncada/angular-datetime-picker";
const routes: Route[] = [
  {
    path: "",
    component: BaseLayoutComponent,
    children: [
      {
        path: PATH.MAIN.REFERAL_CODE,
        loadChildren: () =>
          import("./referal-code/referal-code.module").then((m) => m.ReferalCodeModule),
        data: { name: "Student" },
      },
      {
        path: PATH.MAIN.STUDENT,
        loadChildren: () =>
          import("./student/student.module").then((m) => m.StudentModule),
        data: { name: "Student" },
      },
      {
        path: PATH.MAIN.TRANSACTION,
        loadChildren: () =>
          import("./transaction/transaction.module").then(
            (m) => m.TransactionModule
          ),
        data: { name: "Student" },
      },
      {
        path: PATH.MAIN.EXAM_PAPER_TRANSACTION,
        loadChildren: () =>
          import("./exam-paper-transaction/exam-paper.module").then(
            (m) => m.ExamPaperTransactionModule
          ),
        data: { name: "Student" },
      },
      {
        path: PATH.MAIN.SUBSCRIPTION,
        loadChildren: () =>
          import("./subscription/subscription.module").then(
            (m) => m.SubscriptionModule
          ),
        data: { name: "Student" },
      },
      {
        path: PATH.MAIN.TUTOR,
        loadChildren: () =>
          import("./tuitor/tuitor.module").then((m) => m.TuitorModule),
        data: { name: "Tuitor" },
      },
      {
        path: PATH.MAIN.QUESTION,
        loadChildren: () =>
          import("./question/question.module").then((m) => m.QuestionModule),
      },
      {
        path: PATH.MAIN.PREDICTED_PAPER,
        loadChildren: () =>
          import("./predicted-paper/predicted-paper.module").then((m) => m.PredictedPaperModule),
      },
      {
        path: PATH.MAIN.SESSION,
        loadChildren: () =>
          import("./session/sesion.module").then((m) => m.SessionModule),
      },
      { path: "dashboard", component: DashboardComponent },
      {
        path: PATH.MAIN.COURSES,
        loadChildren: () =>
          import("./courses/module/courses.module").then((m) => m.CoursesModule),
      },
      {
        path: PATH.MAIN.COURSES_TRANSACTION,
        loadChildren: () =>
          import("./courses-transactions/module/courses-transaction.module").then(
            (m) => m.CoursesTransactionModule
          ),
        data: { name: "Student" },
      },
    ],
  },
];
@NgModule({
  declarations: [
    BaseLayoutComponent,
    FooterComponent,
    HeaderComponent,
    PageTitleComponent,
    PagesLayoutComponent,
    SidebarComponent,
    SearchBoxComponent,
    UserBoxComponent,
    DashboardComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FontAwesomeModule,
    NgbModule,
    LoadingBarRouterModule,
    NgScrollbarModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  exports: [],
})
export class MainModule {}
