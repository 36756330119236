import { Component, HostListener, OnInit } from "@angular/core";
import {  Router } from "@angular/router";
import { PARENT_PATH, PATH } from "src/app/common/constant";
import { ThemeOptions } from "src/app/modules/main/ThemeOptions/store/theme-options";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  currentTab = "dashboard";
  private newInnerWidth: number;
  private innerWidth: number;

  constructor(public globals: ThemeOptions, private router: Router) {}
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;
    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });
    this.getCurrentTab();
  }

  getCurrentTab() {
    let tab = this.router.url.split("/");
    if (tab.length > 2) {
      this.currentTab = this.router.url.split("/")[2];
    } else {
      this.currentTab = "dashboard";
    }
  }

  //toggle the sidebar
  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  showDeatil(id: string) {
    switch (id) {
      case "dashboard":
        this.router.navigateByUrl(`${PARENT_PATH.MAIN}/${PATH.MAIN.DASHBOARD}`);
        this.currentTab = "dashboard";
        break;
      case "student":
        this.router.navigateByUrl(`${PARENT_PATH.MAIN}/${PATH.MAIN.STUDENT}`);
        this.currentTab = "student";
        break;
      
      case "tutor":
        this.router.navigateByUrl(`${PARENT_PATH.MAIN}/${PATH.MAIN.TUTOR}`);
        this.currentTab = "tutor";
        break;

      case "transaction":
        this.router.navigateByUrl(
          `${PARENT_PATH.MAIN}/${PATH.MAIN.TRANSACTION}`
        );
        this.currentTab = "transaction";
        break;

      case "question":
        this.router.navigateByUrl(`${PARENT_PATH.MAIN}/${PATH.MAIN.QUESTION}`);
        this.currentTab = "question";
        break;

      case "predicted-paper":
        this.router.navigateByUrl(`${PARENT_PATH.MAIN}/${PATH.MAIN.PREDICTED_PAPER}`);
        this.currentTab = "predicted-paper";
        break;
        
        case "examPaperTransaction":
          this.router.navigateByUrl(
            `${PARENT_PATH.MAIN}/${PATH.MAIN.EXAM_PAPER_TRANSACTION}`
          );
          this.currentTab = "examPaperTransaction";
          break;
          case "subscription":
          this.router.navigateByUrl(
            `${PARENT_PATH.MAIN}/${PATH.MAIN.SUBSCRIPTION}`
          );
          this.currentTab = "subscription";
          break;
          case "session":
          this.router.navigateByUrl(
            `${PARENT_PATH.MAIN}/${PATH.MAIN.SESSION}`
          );
          this.currentTab = "session";
          break;
        case "courses":
        this.router.navigateByUrl(`${PARENT_PATH.MAIN}/${PATH.MAIN.COURSES}`);
        this.currentTab = "courses";
        break;
        case "coursesTransactions":
          this.router.navigateByUrl(`${PARENT_PATH.MAIN}/${PATH.MAIN.COURSES_TRANSACTION}`);
          this.currentTab = "coursesTransactions";
          break;
        case "referal-code":
          this.router.navigateByUrl(`${PARENT_PATH.MAIN}/${PATH.MAIN.REFERAL_CODE}`);
          this.currentTab = "referal-code";
          break;        
    }
  }
}
