<div class="main-card mb-3 card">
  <div class="card-header d-flex justify-content-end">
    <div class="search-wrapper" [class.active]="isActive">
      <div class="input-holder">
        <input
          type="text "
          placeholder="Type to search "
          class="search-input"
          [(ngModel)]="word"
          (keydown.enter)="wordSearch()"
        />
        <button class="search-icon" (click)="wordSearch()">
          <span></span>
        </button>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th class="text-center text-muted" style="width: 80px">#</th>
          <th class="text-center">Name</th>
          <th class="text-center">Email</th>
          <th class="text-center">Created At</th>
          <th class="text-center">Actions</th>
        </tr>
      </thead>
      <tbody *ngIf="parentList?.length > 0">
        <tr *ngFor="let parent of parentList">
          <td class="text-center text-muted" style="width: 80px">
            {{ parent.id }}
          </td>
          <td class="text-center">{{ parent?.name }}</td>
          <td class="text-center">{{ parent?.email }}</td>
          <td class="text-center">{{ parent?.created_at | date: 'dd-MM-yy' }}</td>
          <td class="text-center">
            <div
              role="group "
              class="btn-group-sm btn-group justify-content-between"
            >
              <a class="view" (click)="viewParentChildModal(parent.id)"
                ><i class="fa fa-fw" aria-hidden="true" title="eye"></i></a
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="text-center"
      style="font-size: 20px; text-align: center; margin-top: 40px"
      *ngIf="parentList.length == 0"
    >
      <p>No Records</p>
    </div>
  </div>
  <ngb-pagination
    *ngIf="parentList && parentList.length != 0 && totalCount"
    class="d-flex justify-content-center"
    style="margin-top: 30px"
    [collectionSize]="totalCount"
    [maxSize]="3"
    [rotate]="true"
    [boundaryLinks]="true"
    [(page)]="page"
    (pageChange)="pageChange($event)"
  ></ngb-pagination>
</div>
