import { Component } from '@angular/core';
import { getParentAndChildListFilters } from '../entities/parent-and-child-input.entity';
import { SpinnerService } from 'src/app/core/service/spinner.service';
import { ParentAndChildService } from 'src/app/core/service/parent-and-child-service';
import { Child } from '../entities/parent-and-child-list-response.entity';
import { ActivatedRoute } from '@angular/router';
import { Location } from "@angular/common";

@Component({
  selector: 'app-list-parent-child',
  templateUrl: './list-parent-child.component.html',
  styleUrl: './list-parent-child.component.css'
})
export class ListParentChildComponent {
  public isActive: boolean = true;
  pagination = {
    take: 10,
    skip: 0,
  };
  page = 0;
  pageNumber: number = 1;
  totalCount!: number;
  parentChildList: Child[] = [];
  isParent: boolean = false;
  parentId!:number;
  constructor(
    private spinner: SpinnerService,
    private parentChildService: ParentAndChildService,
    private location: Location,
    private route: ActivatedRoute
  ) {
    // Extract Parent ID from route parameters
    const parentId = this.route.snapshot.queryParamMap.get('id');
    // Check if Parent ID exists and is a valid number
    if (parentId && !isNaN(+parentId)) {
      this.parentId = +parentId;
    }
  }

  /**
   * ngOninit
   **/
  ngOnInit(): void {
    if(this.parentId){
      this.getPageValue();
    }
  }

  /**
   * function to get page value after coming back from view detail page.
   * pagination ang page value will be stored in service through get and set method
   */
  getPageValue() {
    if (this.parentChildService.getNewPagination) {
      this.pagination = this.parentChildService.getNewPagination;
      this.page = this.parentChildService.newPageNumber;
    } else {
      this.pagination = this.pagination;
    }
    this.getParentChildList();
  }

  /**
   * Get the list of ParentChild
   * Makes a service call to ReferralCodeList with the pagination and search parameters
   * @param data - The pagination and search parameters
   * @returns The list of ParentChild
   */
  async getParentChildList() {
    this.spinner.showSpinner();
    let data: getParentAndChildListFilters = {
      ...this.pagination,
      parent:this.parentId
    };
    const res = await this.parentChildService.adminParentChildList({
      adminListParentChildInput: data,
    });
    this.spinner.hideSpinner();
    if (res) {
      this.parentChildList = res?.children;
      this.totalCount = res.count;
    } else {
      this.parentChildList.length = 0;
    }
  }

  /**
   * pagination
   * @param page --current page
   */
  pageChange(page: number) {
    this.pagination.skip = (page - 1) * 10;
    this.parentChildService.setPagination = this.pagination;
    this.parentChildService.setPageNumber = this.page;
    this.pageNumber = this.page;
    this.getParentChildList();
  }

  goBack(){
    this.location.back();
  }
}
