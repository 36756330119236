import { Injectable } from "@angular/core";
import { Authenticate } from "src/app/graphql/service";
import { SpinnerService } from "./spinner.service";
import { CreateReferralCodeInputEntity, UpdateReferralCodeInputEntity, getReferralCodeListInputEntity, AdminGetDeleteReferralCode } from "src/app/modules/main/referal-code/entities/referral-code.input.entity";
import { AdminListParentChildInputEntity, AdminListParentsInputEntity, getParentAndChildListFilters } from "src/app/modules/main/list-parent/entities/parent-and-child-input.entity";

@Injectable({
  providedIn: "root",
})
export class ParentAndChildService {
  constructor(private service: Authenticate, spinner: SpinnerService) {}

  private _pageValues: any;
  private pageNumber: number;

  set setPagination(newValue: any) {
    this._pageValues = newValue;
  }

  set setPageNumber(page: number) {
    this.pageNumber = page;
  }

  get getNewPagination(): any {
    return this._pageValues;
  }

  get newPageNumber() {
    return this.pageNumber;
  }

  adminParentList(data: AdminListParentsInputEntity) {
    return this.service.adminListParents(data);
  }

  adminParentChildList(data: AdminListParentChildInputEntity) {
    return this.service.adminListParentChild(data);
  }
}
