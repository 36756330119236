<div [class]="'app-sidebar sidebar-shadow '">
    <!-- 'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme -->
    <div class="app-header__logo">
        <div [ngClass]="!globals.toggleSidebar ? 'logo-src' : ''"></div>
        <div class="header__pane ms-auto">
            <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
                [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar()">
                <span class="hamburger-box" style="margin-top: 7px;">
                    <span class="hamburger-inner"></span>
                </span>
            </button>
        </div>
        <div class="dpn">
            <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" (click)="toggleSidebar()">
                <span class="hamburger-box" style="margin-top: 7px;">
                    <span class="hamburger-inner"></span>
                </span>
            </button>
        </div>
    </div>
    <div class="app-sidebar-content ">
        <div class="app-sidebar-scroll">
            <ng-scrollbar shown="native" trackX="false">
                <div>
                    <div class="v-sidebar-menu vsm-default ">
                        <div class="vsm-list ">
                            <div class="vsm-header">Main Navgation</div>
                            <div class="vsm-item">
                                <a [ngClass]="currentTab == 'dashboard' ?'vsm-link active-item' : 'vsm-link'" id="dashboard"
                                    (click)="showDeatil('dashboard')">
                                    <i class="vsm-icon fa fa-fw " aria-hidden="true " title="newspaper-o "></i>
                                    <span class="vsm-title ">Dashboard</span>
                                </a>
                            </div>
                        </div>
                        <div class="vsm-list ">
                            <div class="vsm-header ">Users</div>
                            <div class="vsm-item ">
                                <a [ngClass]="currentTab == 'student' ?'vsm-link active-item' : 'vsm-link'" id="student"
                                    (click)="showDeatil('student') ">
                                    <i class="vsm-icon fa fa-fw " aria-hidden="true " title="user "></i>
                                    <span class="vsm-title ">Students</span>
                                </a>
                            </div>
                            <div class="vsm-item ">
                                <a [ngClass]="currentTab == 'tutor' ?'vsm-link active-item' : 'vsm-link'" id="tutor"
                                    (click)="showDeatil('tutor') ">
                                    <i class="vsm-icon fa fa-fw " aria-hidden="true " title="users "></i>
                                    <span class="vsm-title ">Tutors</span>
                                </a>
                            </div>
                            <div class="vsm-item ">
                                <a [ngClass]="currentTab == 'parents' ?'vsm-link active-item' : 'vsm-link'" id="parents"
                                    (click)="showDeatil('parents') ">
                                    <i class="vsm-icon fa fa-fw " aria-hidden="true " title="user "></i>
                                    <span class="vsm-title ">Parents</span>
                                </a>
                            </div>
                        </div>
                        <div class="vsm-list ">
                            <div class="vsm-header ">Finance</div>
                            <div class="vsm-item ">
                                <a [ngClass]="currentTab == 'transaction' ?'vsm-link active-item' : 'vsm-link'"
                                    id="transaction" class="vsm-link " (click)="showDeatil('transaction') ">
                                    <i class="vsm-icon fa fa-fw " aria-hidden="true " title="money "></i>
                                    <span class="vsm-title ">Transactions</span>
                                </a>
                            </div>
                            <div class="vsm-item ">
                                <a [ngClass]="currentTab == 'examPaperTransaction' ?'vsm-link active-item' : 'vsm-link'"
                                    id="examPaperTransaction" class="vsm-link "
                                    (click)="showDeatil('examPaperTransaction') ">
                                    <i class="vsm-icon fa fa-fw " aria-hidden="true " title="money "></i>
                                    <span class="vsm-title ">Exam Paper Transactions</span>
                                </a>
                            </div>
                            <div class="vsm-item ">
                                <a [ngClass]="currentTab == 'subscription' ?'vsm-link active-item' : 'vsm-link'"
                                    id="subscription" class="vsm-link "
                                    (click)="showDeatil('subscription') ">
                                    <i class="vsm-icon fa fa-fw " aria-hidden="true " title="money "></i>
                                    <span class="vsm-title ">Subscription</span>
                                </a>
                            </div>
                            <div class="vsm-item">
                                <a [ngClass]="currentTab == 'coursesTransactions'?'vsm-link active-item' : 'vsm-link'" id="coursesTransactions"
                                    class="vsm-link " (click)="showDeatil( 'coursesTransactions') ">
                                    <i class="vsm-icon fa fa-fw fa-file-text-o" aria-hidden="true " title=" "></i>
                                    <span class="vsm-title ">Course Transactions</span>
                                </a>
                            </div>
                            <div class="vsm-item ">
                                <a [ngClass]="currentTab == 'bundleTransaction' ?'vsm-link active-item' : 'vsm-link'"
                                    id="bundleTransaction" class="vsm-link "
                                    (click)="showDeatil('bundleTransaction') ">
                                    <i class="vsm-icon fa fa-fw " aria-hidden="true " title="money "></i>
                                    <span class="vsm-title ">Bundle Transactions</span>
                                </a>
                            </div> 
                        </div>
                        <div class="vsm-list ">
                            <div class="vsm-header">Exam</div>
                            <div class="vsm-item">
                                <a [ngClass]="currentTab == 'question'?'vsm-link active-item' : 'vsm-link'" id="question"
                                    class="vsm-link " (click)="showDeatil( 'question') ">
                                    <i class="vsm-icon fa fa-fw fa-bolt" aria-hidden="true " title=" "></i>
                                    <span class="vsm-title ">Flash Cards</span>
                                </a>
                            </div>
                            <div class="vsm-item">
                                <a [ngClass]="currentTab == 'predicted-paper'?'vsm-link active-item' : 'vsm-link'"
                                    id="predicted-paper" class="vsm-link " (click)="showDeatil( 'predicted-paper') ">
                                    <i class="vsm-icon fa fa-fw fa-file-text-o" aria-hidden="true " title="Exam Paper"></i>
                                    <span class="vsm-title ">Exam Paper</span>
                                </a>
                            </div>
                            <div class="vsm-item">
                                <a [ngClass]="currentTab == 'session'?'vsm-link active-item' : 'vsm-link'" id="session"
                                    class="vsm-link " (click)="showDeatil( 'session') ">
                                    <i class="vsm-icon fa fa-fw fa-play-circle" aria-hidden="true " title="Session"></i>
                                    <span class="vsm-title ">Session</span>
                                </a>
                            </div>
                            <div class="vsm-item">
                                <a [ngClass]="currentTab == 'bundle' ?'vsm-link active-item' : 'vsm-link'" id="bundle" class="vsm-link "
                                    (click)="showDeatil('bundle') ">
                                    <i class="vsm-icon fa fa-fw fa-file-text-o" aria-hidden="true " title="bundle"></i>
                                    <span class="vsm-title ">Bundle Exam Paper</span>
                                </a>
                            </div> 
                        </div>
                        <div class="vsm-list ">
                            <div class="vsm-header">Courses</div>
                            <div class="vsm-item">
                                <a [ngClass]="currentTab == 'courses'?'vsm-link active-item' : 'vsm-link'" id="courses"
                                    class="vsm-link " (click)="showDeatil( 'courses') ">
                                    <i class="vsm-icon fa fa-fw fa-file-text-o" aria-hidden="true " title=" "></i>
                                    <span class="vsm-title ">Courses</span>
                                </a>
                            </div>                     
                        </div>
                        <div class="vsm-list ">
                            <div class="vsm-header">Referal Code</div>
                            <div class="vsm-item ">
                                <a [ngClass]="currentTab == 'referal-code' ?'vsm-link active-item' : 'vsm-link'" id="referal-code"
                                    (click)="showDeatil('referal-code') ">
                                    <i class="vsm-icon fa fa-fw " aria-hidden="true " title="referal-code"></i>
                                    <span class="vsm-title ">Referal Code</span>
                                </a>
                            </div>                     
                        </div>
                    </div>
                </div>
              </ng-scrollbar>
        </div>
    </div>
</div>