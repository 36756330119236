import { environment } from "src/environments/environment";

export const REGEX = {
  PASSWORD: /^(?=.*[A-Za-z])(?=(.*[\d]){1,})(?=.*?[^\w\s]).{8,}$/, //Contains 8 characters atleast 1 number, 1 alphabet, 1 special char
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/,
     URL : /\bhttps?:\/\/\S+/

};

export const URL = {
  UPLAOD_IMAGE: "https://api.flashlearning.co.uk/user/profilePictureUpload",
  UPLAOD_FILE:  `${environment.fileUploadUrl}/predictedPapers/paperUpload`,
};

export const PARENT_PATH = {
  MAIN: "main",
  AUTH: "auth",
  WILDCARD: "**",
};

export const PATH = {
  DEFAULT: "",
  AUTH: {
    LOGIN: "login",
    FORGOT: "forgot",
  },
  MAIN: {
    BASE_LAYOUT: "baseLayout",
    STUDENT: "student",
    TUTOR: "tutor",
    TRANSACTION: "transaction",
    DASHBOARD: "dashboard",
    QUESTION: "question",
    PREDICTED_PAPER: "predicted-paper",
    EXAM_PAPER_TRANSACTION:"examPaperTransaction",
    SESSION:"session",
    SUBSCRIPTION:'subscription',
    COURSES:'courses',
    COURSES_TRANSACTION:"coursesTransactions",
    DETAIL:"detail",
    HISTORY:"history",
    REFERAL_CODE:"referal-code",
  },
};

export const USER_STATUS = {
  ACTIVE: 1,
  DEACTIVATE: 2,
};

export enum FILTERS {
  SUBJECT = 1,
  EXAM_BOARD = 2,
  LEVEL = 3,
  MODULE = 4,
  QUESTION = 5,
  QUALIFICATION=6,
  TITLE=7,
  PRODUCT_TYPE = 8,
  TIER = 9
}

export const LICENCE_KEY_OF_EDITOR =
  "fIE3A-9C2I2F2C4D4B4D2uifC-7A3hcqbzC-9qtdyqwD-11A2C-9rB2jkB-16D4E3D2F3C3I2F1B10B2C1==";

  export const UPLOAD_TIME_OUT = 600000
export enum CommonText {
  pdftitle = "title",
  pdf = "pdf_url",
  marks_scheme = "marks_scheme",
  Edit = 'Edit',
  Create = 'Create',
  DONE='done'
}

export enum Status {
  ACTIVE = "1",
  DEACTIVATE = "DeActivate"
}
export enum StatusFilter {
  Active = 1,
  DeActivate = 2
}


export enum FileType{
  pdf="pdf"
}

// Membership/Subscription Status enum
export enum SubscriptionFilter {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE"
}
export const dateFormat = 'dd MMM yyyy hh:mm a'; 
export enum subscriptionStatus{
  ACTIVE='Active',
  INACTIVE='Inactive'
}

