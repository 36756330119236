import { Injectable } from "@angular/core";
import { Authenticate } from "src/app/graphql/service";
import { AdminListBundleTransactionPaperInput } from "src/app/modules/main/purchase-exam-paper-list/entities/purchase-exam-paper.input.entity";

@Injectable({
  providedIn: "root",
})
export class BundlePurchaseExamPaperService {
  constructor(private service: Authenticate) {}

  private _pageValues: any;
  private pageNumber: number;

  set setPagination(newValue: any) {
    this._pageValues = newValue;
  }

  set setPageNumber(page: number) {
    this.pageNumber = page;
  }

  get getNewPagination(): any {
    return this._pageValues;
  }

  get newPageNumber() {
    return this.pageNumber;
  }

  getAdminBundleTransactionPapers(data: AdminListBundleTransactionPaperInput) {
    return this.service.getBundleTransactionPapers(data);
  }
}
