import { Component } from '@angular/core';
import { Parent } from '../entities/parent-and-child-list-response.entity';
import { ParentAndChildService } from 'src/app/core/service/parent-and-child-service';
import { SpinnerService } from 'src/app/core/service/spinner.service';
import { getParentAndChildListFilters } from '../entities/parent-and-child-input.entity';
import { Router } from '@angular/router';
import { PARENT_PATH, PATH } from 'src/app/common/constant';

@Component({
  selector: 'app-list-parents',
  templateUrl: './list-parents.component.html',
  styleUrl: './list-parents.component.css'
})
export class ListParentsComponent {
  public isActive: boolean = true;
  word: string = "";
  pagination = {
    take: 10,
    skip: 0,
  };
  page = 0;
  pageNumber: number = 1;
  totalCount!: number;
  parentList: Parent[] = [];
  isParent: boolean = false;
  
  constructor(
    private spinner: SpinnerService,
    private parentChildService: ParentAndChildService,
    private router: Router
  ) {}

  /**
   * ngOninit
   **/
  ngOnInit(): void {
    this.getPageValue();
  }

  /**
   * function to get page value after coming back from view detail page.
   * pagination ang page value will be stored in service through get and set method
   */
  getPageValue() {
    if (this.parentChildService.getNewPagination) {
      this.pagination = this.parentChildService.getNewPagination;
      this.page = this.parentChildService.newPageNumber;
    } else {
      this.pagination = this.pagination;
    }
    this.getAdminParentList();
  }

  /**
   * Get the list of referral codes
   * Makes a service call to ReferralCodeList with the pagination and search parameters
   * @param data - The pagination and search parameters
   * @returns The list of referral codes
   */
  async getAdminParentList() {
    this.spinner.showSpinner();
    let data: getParentAndChildListFilters = {
      ...this.pagination
    }
    if (this.word != "") {
      data["search"] = this.word;
    }
    const res = await this.parentChildService.adminParentList({adminListParentsInput:data});
    this.spinner.hideSpinner();
    if (res) {
      this.parentList = res?.parents;
      this.totalCount = res.count;
    } else {
      this.parentList.length = 0;
    }
  }

  /**
   * pagination
   * @param page --current page
   */
  pageChange(page: number) {
    this.pagination.skip = (page - 1) * 10;
    this.parentChildService.setPagination = this.pagination;
    this.parentChildService.setPageNumber = this.page;
    this.pageNumber = this.page;
    this.getAdminParentList();
  }

  /**
   * function user to perform search operation
   */
  wordSearch() {
    this.word.trim();
    this.getAdminParentList();
  }

  viewParentChildModal(parentId: number): void {    
    this.router.navigate([`${PARENT_PATH.MAIN}/${PATH.MAIN.PARENT_CHILD}`], { queryParams: { id: parentId }});
    this.isParent = false;
  }
}
