<div class="main-card mb-3 card">
  <div class="table-responsive">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th class="text-center text-muted" style="width: 80px">#</th>
          <th class="text-center">Transaction Id</th>
          <th class="text-center">Predicted Paper</th>
          <th class="text-center">Qualification</th>
          <th class="text-center">Examboard</th>
          <th class="text-center">Subject</th>
          <th class="text-center">Product Type</th>
          <th class="text-center">Paper Type</th>
          <th class="text-center">Created At</th>
        </tr>
      </thead>
      <tbody *ngIf="purchaseExamPaperList?.length > 0">
        <tr *ngFor="let examPaperT of purchaseExamPaperList">
          <td class="text-center text-muted" style="width: 80px">
            {{ examPaperT?.id }}
          </td>
          <td class="text-center">{{ examPaperT?.transaction_id }}</td>
          <td class="text-center">{{ examPaperT?.predicted_Paper?.title }}</td>
          <td class="text-center">
            {{ examPaperT?.predicted_Paper?.qualification?.name }}
          </td>
          <td class="text-center">
            {{ examPaperT?.predicted_Paper?.examboard?.name }}
          </td>
          <td class="text-center">
            {{ examPaperT?.predicted_Paper?.subject?.name }}
          </td>
          <td class="text-center">
            {{ examPaperT?.predicted_Paper?.productType?.name }}
          </td>
          <td class="text-center">
            {{ examPaperT?.predicted_Paper?.paperType?.name }}
          </td>
          <td class="text-center">
            {{ examPaperT?.created_at | date : "dd-MM-yy" }}
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="text-center"
      style="font-size: 20px; text-align: center; margin-top: 40px"
      *ngIf="purchaseExamPaperList?.length == 0"
    >
      <p>No Records</p>
    </div>
  </div>
  <div class="row" *ngIf="purchaseExamPaperList?.length > 0 && totalCount">
    <div class="col-md-10">
      <ngb-pagination
        class="d-flex justify-content-center"
        style="margin-top: 30px"
        [collectionSize]="totalCount"
        [maxSize]="3"
        [rotate]="true"
        [boundaryLinks]="true"
        [(page)]="page"
        (pageChange)="pageChange($event)"
      ></ngb-pagination>
    </div>
  </div>
  <div class="d-flex justify-content-end mb-3">
    <a class="btn btn-sm btn-primary me-2" (click)="goBack()">Go Back</a>
  </div>
</div>
