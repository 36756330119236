<div class="main-card mb-3 card">
  <div class="table-responsive">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th class="text-center text-muted" style="width: 80px">#</th>
          <th class="text-center">Title</th>
          <th class="text-center">Email</th>
          <th class="text-center">Created At</th>
        </tr>
      </thead>
      <tbody *ngIf="parentChildList?.length > 0">
        <tr *ngFor="let child of parentChildList">
          <td class="text-center text-muted" style="width: 80px">
            {{ child.id }}
          </td>
          <td class="text-center">{{ child?.children.name }}</td>
          <td class="text-center">{{ child?.children.email }}</td>
          <td class="text-center">{{ child?.created_at | date: 'dd-MM-yy' }}</td>
        </tr>
      </tbody>
    </table>
    <div
      class="text-center"
      style="font-size: 20px; text-align: center; margin-top: 40px"
      *ngIf="parentChildList?.length == 0"
    >
      <p>No Records</p>
    </div>
  </div>
  <div class="row" *ngIf="parentChildList?.length > 0 && totalCount">
    <div class="col-md-10">
      <ngb-pagination
        class="d-flex justify-content-center"
        style="margin-top: 30px"
        [collectionSize]="totalCount"
        [maxSize]="3"
        [rotate]="true"
        [boundaryLinks]="true"
        [(page)]="page"
        (pageChange)="pageChange($event)"
      ></ngb-pagination>
    </div>
  </div>
  <div class="d-flex justify-content-end mb-3">
    <a class="btn btn-sm btn-primary me-2" (click)="goBack()">Go Back</a>
  </div>
</div>

