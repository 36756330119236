// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appAccessToken: "app_access_token_91jQJBu*LPu3@C7!rYjht$#@#$jk66+",
  baseUrl: "http://18.135.117.187:3000/graphql",
  fileUploadUrl:"http://18.135.117.187:3000"
};

// http://18.135.117.187:3000/graphql
// baseUrl: "http://10.10.10.111:5003/graphql"

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
