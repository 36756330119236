import { Component } from "@angular/core";
import { Transaction } from "./entities/purchase-exam-paper.response.entity";
import { SpinnerService } from "src/app/core/service/spinner.service";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { BundlePurchaseExamPaperService } from "src/app/core/service/bundle-purchase-exam-paper.service";
import { AdminListBundleTransactionPaperInput } from "src/app/modules/main/purchase-exam-paper-list/entities/purchase-exam-paper.input.entity";

@Component({
  selector: "app-purchase-exam-paper-list",
  templateUrl: "./purchase-exam-paper-list.component.html",
  styleUrl: "./purchase-exam-paper-list.component.css",
})
export class PurchaseExamPaperListComponent {
  pagination = {
    take: 10,
    skip: 0,
  };
  page = 0;
  pageNumber: number = 1;
  totalCount!: number;
  purchaseExamPaperList: Transaction[];
  bannerId!: number;
  constructor(
    private spinner: SpinnerService,
    private location: Location,
    private route: ActivatedRoute,
    private bundleService: BundlePurchaseExamPaperService
  ) {
    // Extract banner ID from route parameters
    const bannerId = this.route.snapshot.queryParamMap.get("id");
    // Check if banner ID exists and is a valid number
    if (bannerId && !isNaN(+bannerId)) {
      this.bannerId = +bannerId;
    }
  }

  /**
   * ngOninit
   **/
  ngOnInit(): void {
    if (this.bannerId) {
      this.getPageValue();
    }
  }

  /**
   * function to get page value after coming back from view detail page.
   * pagination ang page value will be stored in service through get and set method
   */
  getPageValue() {
    if (this.bundleService.getNewPagination) {
      this.pagination = this.bundleService.getNewPagination;
      this.page = this.bundleService.newPageNumber;
    } else {
      this.pagination = this.pagination;
    }
    this.getBundleTransacttionPapersList();
  }

  /**
   * Get the list of bundle transaction papers list
   * @param data - The pagination and search parameters
   * @returns The list of bundle transaction papers
   */
  async getBundleTransacttionPapersList() {
    this.spinner.showSpinner();
    let data: AdminListBundleTransactionPaperInput = {
      adminListBundleTransactionPaperInput: {
        ...this.pagination,
        id: this.bannerId,
      },
    };
    const res = await this.bundleService.getAdminBundleTransactionPapers(data);
    this.spinner.hideSpinner();
    if (res) {
      this.purchaseExamPaperList = res?.transaction;

      this.totalCount = res.count;
    } else {
      this.purchaseExamPaperList.length = 0;
    }
  }

  /**
   * pagination
   * @param page --current page
   */
  pageChange(page: number) {
    this.pagination.skip = (page - 1) * 10;
    this.bundleService.setPagination = this.pagination;
    this.bundleService.setPageNumber = this.page;
    this.pageNumber = this.page;
    this.getBundleTransacttionPapersList();
  }

  goBack() {
    this.location.back();
  }
}
